import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta:{
      libre:true
    }
  },

  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue'),
    meta:{
      administrador:true,
      usuario_comun:true
    }
  },


  {
    path: '/departamentos',
    name: 'departamentos',
    component: () => import('../views/Departamentos.vue'),
    meta:{
      administrador:true,
      usuario_comun:true
    }
  },

  {
    path: '/empleados',
    name: 'empleados',
    component: () => import('../views/Empleados.vue'),
    meta:{
      administrador:true,
      usuario_comun:true
    }
  },

  {
    path: '/planillas',
    name: 'planillas',
    component: () => import('../views/Planillas.vue'),
    meta:{
      administrador:true,
      usuario_comun:true
    }
  },

  {
    path: '/vacaciones',
    name: 'vacaciones',
    component: () => import('../views/Vacaciones.vue'),
    meta:{
      administrador:true,
      usuario_comun:true
    }
  },

  {
    path: '/deducciones',
    name: 'deducciones',
    component: () => import('../views/Deducciones.vue'),
    meta:{
      administrador:true,
      usuario_comun:true
    }
  },

  {
    path: '/configuracion',
    name: 'configuracion',
    component: () => import('../views/Configuracion.vue'),
    meta:{
      administrador:true,
      usuario_comun:true
    }
  },

  {
    path: '/administracion',
    name: 'administracion',
    component: () => import('../views/Administracion.vue'),
    meta:{
      administrador:true,
      usuario_comun:false
    }
  },




]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next)=>{


  const token_storage=localStorage.getItem('token');


  if(token_storage!=null){
  
  
    if(!store.state.usuario){
      console.log('token encontrado');
      console.log(token_storage);
      store.dispatch("guardarToken", token_storage);
    }
  
  }





  if(to.matched.some(record=>record.meta.libre)){
    next();


    //validamos rutas solo administrador
  }else if( store.state.usuario && store.state.usuario.rol=='ADMIN'){

    if(to.matched.some(record=>record.meta.administrador)){
      next();
    }

    //ruta para usuarios comunes
  }else if(store.state.usuario && store.state.usuario.rol=='USER'){

    if(to.matched.some(record=>record.meta.usuario_comun)){
          next();
        }

    //si no tiene ningun permiso salir
  }else{
    next({name:'login'});
    console.log('entro en ruta libre');

  }


});

export default router
