import Vue from 'vue'
import Vuex from 'vuex'
import decode from 'jwt-decode';
import router from '../router';

Vue.use(Vuex)

export default new Vuex.Store({
   //solo lectura el state
   state: {
            token:null,
            usuario:null,

            loading:{
              titulo:'',
              estado:false,
              color:'primary'
            },
  },
  mutations: {

    mostrarLoading(state, payload){
      state.loading.titulo = payload.titulo
      state.loading.estado = true
      state.loading.color=payload.color
    },

    ocultarLoading(state){
     state.loading.estado=false
    },

    //mofican el estado del state->token
    setToken(state, token){
      state.token=token;
    },
    
    //modifica el estado del state->usuario
    setUsuario(state, payload){
        if(payload!=null && payload!=''){
          let usuarioDB=decode(payload);
          state.usuario=usuarioDB.data;
          localStorage.setItem("usuario", usuarioDB);
          console.log(state.usuario);
        }
    }




  },
  actions: {


      //llamado al momento de logear en login
      guardarToken({commit}, token){
        commit("setUsuario", token); //ejecuta la mutacion que establece valor de usuario
        localStorage.setItem("token", token);
        commit("setToken", token);      //ejecuta la mutacion que establece valor de token
        //redirecciona al guardar token
        router.push({name:'home'});
      },

      //llamdo al ingreso para omitir login
      autoLogin({commit}){
          let token=localStorage.getItem("token");
          if(token!=null){
            commit("setToken", token);     
            commit("setUsuario", token); 
            router.push({name:'vehiculos'});
          }
      },

      //borra los datos de local storage y asigna null a token y usuario
      salir({commit}){
        localStorage.removeItem("token");
        commit("setToken", null);     
        commit("setUsuario", null);
        router.push({name:'login'});
      }


  },
  modules: {
  }
})
